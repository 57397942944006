import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Close from "../../assets/svg/Close";
import Loader from "../common/Loader";
import { getListing } from "../../api/merchant";

export const MerchantListings = ({
  isOpen,
  onClose,
  setSelectedListing,
  selectedListing,
  isDashboard
}) => {
  const dispatch = useDispatch();

  const sRowPerPage = 30;
  const [aListings, setListings] = useState();
  const [bLoading, setLoading] = useState(false);
  const [bLastPage, setLastPage] = useState(false);
  const [iPage, setPage] = useState(1);

  useEffect(() => {
    if (isOpen) {
      handleListings();
    }
  }, [isOpen]);

  const handleListings = async () => {
    if (bLoading || bLastPage) {
      return;
    }

    setLoading(true);
    const _requestData = {
      page_no: iPage,
      row_per_page: sRowPerPage,
    };

    try {
      const response = await getListing(_requestData);
      if (response.error === 0) {
        if (
          response.data.pagination.current_page === 1 &&
          response.data.services_list.length === 0
        ) {
          setLastPage(true);
          setLoading(false);
          return;
        }

        setLoading(false);
        if (response.data.pagination.current_page === 1) {
          // if (response.data.services_list.length !== 0 && !selectedListing) {
          //   setSelectedListing(response.data.services_list[0]);
          // }
          setListings(response.data.services_list);
          setPage(2);
        } else {
          const listings = aListings?.concat(response.data.services_list);
          setListings(listings);
          setPage(response.data.pagination.current_page + 1);
        }

        if (
          response.data.pagination.current_page ===
          response.data.pagination.total_page
        ) {
          setLastPage(true);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleOnClose = () => {
    setListings([]);
    setLoading(false);
    setLastPage(false);
    setPage(1);
    onClose();
  };

  const handleClick = (data) => {
    setSelectedListing(data);
    handleOnClose();
  };

  return (
    <div
      style={{ zIndex: "999" }}
      className="fixed inset-0 flex items-center justify-center text-gray-500"
    >
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white py-6 rounded-3xl relative mx-4 w-600">
        <button
          className="px-6 text-gray-400 hover:text-gray-600"
          onClick={handleOnClose}
        >
          <Close className="w-4 h-4" />
        </button>

        <div id="highlights" className="px-8 overflow-y-auto h-600">
          <div className="my-5">
            <div className="text-lg font-semibold">Choose Event Listing</div>
          </div>
          <div id="merchant-highlights" className="">
            <InfiniteScroll
              dataLength={aListings?.length || 0}
              next={() => handleListings()}
              hasMore={!bLastPage}
              loader={
                bLoading ? (
                  <div className="mt-10">
                    <Loader borderColor="border-primary-500" size="h-10 w-10" />
                  </div>
                ) : null
              }
              style={{ overflow: bLoading ? "hidden" : "auto" }}
              scrollThreshold={0.5}
            >
              {!bLoading && aListings?.length === 0 ? (
                <div>No listing found</div>
              ) : (
                <>
                  {isDashboard && <div className="cursor-pointer relative mb-2">
                    <div
                      className="flex flex-row justify-between"
                      onClick={() => handleClick({ service_code: "" })}
                    >
                      <div>
                        <div className="text-primary-500 mt-2">Select All</div>
                        <div className="text-xs">All Listings</div>
                      </div>
                      <span
                        className={`${
                          selectedListing?.service_code === ""
                            ? "opacity-100"
                            : "opacity-20"
                        } h-5 w-5 bg-primary-500  rounded-full inline-block`}
                      ></span>
                    </div>
                  </div>}
                  {aListings?.map((data, i) => (
                    <div key={i} className="cursor-pointer relative">
                      <div
                        className="flex flex-row justify-between"
                        onClick={() => handleClick(data)}
                      >
                        <div>
                          <div className="text-primary-500 mt-2">
                            {data?.title +
                              ` 
                  ${data?.status === "1" ? "(Inactive)" : ""} 
                  ${data?.status === "9" ? "(For Approval)" : ""}` || "-"}
                          </div>
                          <div className="text-xs">{data.location}</div>
                          <div className="text-xs">
                            PHP {data.service_price}
                          </div>
                        </div>
                        <span
                          className={`${
                            selectedListing?.service_code === data.service_code
                              ? "opacity-100"
                              : "opacity-20"
                          } h-5 w-5 bg-primary-500  rounded-full inline-block`}
                        ></span>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};
