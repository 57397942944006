import { regions, provinces } from "select-philippines-address";

export const validatePhoneNumber = (phone) => {
  const inputPhoneNumber = phone;
  const formattedPhoneNumber = inputPhoneNumber.replace(/\D/g, ""); // Remove non-digit characters
  const limitedPhoneNumber = formattedPhoneNumber.slice(0, 10); // Limit to 10 digits
  return limitedPhoneNumber;
};

export const numberOnly = (val) => {
  const formattedNumber = val.replace(/\D/g, ""); // Remove non-digit characters
  return formattedNumber;
};
export const validateMoney = (val) => {
  const formattedNumber = val.replace(/[^\d.]/g, ""); // Remove non-digit and non-decimal point characters
  return formattedNumber;
};
export const validatePercentage = (value) => {
  // Remove non-numeric characters except decimal point
  let numericValue = value.replace(/[^\d.]/g, "");

  // Convert to a number
  let floatValue = parseFloat(numericValue);

  // Ensure the value is between 0 and 100
  if (isNaN(floatValue)) {
    return ""; // Return empty string for invalid input
  } else {
    floatValue = Math.min(Math.max(floatValue, 1), 50);
    return floatValue.toString(); // Convert back to string and return
  }
};

export const validateNumberOfDays = (value) => {
  // Remove non-numeric characters except decimal point
  let numericValue = value.replace(/[^\d.]/g, "");

  // Convert to a number
  let floatValue = parseFloat(numericValue);

  // Ensure the value is between 0 and 100
  if (isNaN(floatValue)) {
    return ""; // Return empty string for invalid input
  } else {
    floatValue = Math.min(Math.max(floatValue, 10), 30);
    return floatValue.toString(); // Convert back to string and return
  }
};

export const validateEmail = (email) => {
  const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return emailPattern.test(email);
};
export const convertToKebabCase = (input) => {
  return input.toLowerCase().replace(/\s+/g, "-");
};

export const islandFormatter = (island) => {
  switch (island) {
    case "Luzon":
    case "Visayas":
    case "Mindanao":
    case "I'm Flexible":
      return "";
    default:
      return island;
  }
};

export const capitalizeFirstLetter = (text) => {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const capitalizeAndReplace = (input) => {
  const words = input
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  const result = words.join(" ");
  return result;
};
export const findEarliestAvailableDate = (blockedDates) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const sortedBlockedDates = blockedDates
    .map((dateString) => new Date(dateString))
    .filter((date) => date >= today)
    .sort((a, b) => a.getTime() - b.getTime());

  let earliestAvailable = new Date(today);
  for (const blockedDate of sortedBlockedDates) {
    const nextDay = new Date(earliestAvailable);
    nextDay.setDate(nextDay.getDate() + 1);

    if (nextDay < blockedDate) {
      return nextDay.toISOString().split("T")[0];
    }

    earliestAvailable = new Date(blockedDate);
    earliestAvailable.setDate(earliestAvailable.getDate());
  }

  return earliestAvailable.toISOString().split("T")[0];
};

export const formatDateToMonthYear = (dateString) => {
  const date = new Date(dateString);
  const month = date.toLocaleString("en-US", { month: "short" });
  const year = date.getFullYear();
  return `${month} ${year}`;
};

export const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  // Get the month, day, and year components
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const year = String(date.getFullYear());
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};

export const dateFormatter = (date) => {
  let formattedDate = "";

  if (date.includes(",")) {
    let arrayDate = date.split(",");

    // Assuming the date format in arrayDate is YYYY/MM/DD
    if (arrayDate.length === 2) {
      const formattedStartDate = formatDate(arrayDate[0]); // Format start date
      const formattedEndDate = formatDate(arrayDate[1]); // Format end date

      if (formattedStartDate === formattedEndDate) {
        formattedDate = formattedStartDate;
      } else {
        formattedDate = `${formattedStartDate} - ${formattedEndDate}`;
      }
    }
  }

  return formattedDate;
};

export const checkLocation = (type, pathname) => {
  const dynamicRegex = /^\/category\/[\w-]+\/[\w-]+$/;
  const regexPattern = /^\/guest\/inbox\/(\d+)$/;
  const isInCategoryPage = dynamicRegex.test(pathname);
  const isInInbox = regexPattern.test(pathname);
  if (isInCategoryPage) {
    return false;
  }
  if (isInInbox) {
    return true;
  }

  return type === "/" ? pathname === type : pathname.includes(type);
};
export const islandFilter = (region) => {
  const LUZON_IDS = [1, 2, 3, 4, 5, 14, 15];
  const VISAYAS_IDS = [6, 7, 8, 9];
  const MINDANAO_IDS = [10, 11, 12, 13, 16, 17];
  if (LUZON_IDS.includes(region)) {
    return "Luzon";
  } else if (VISAYAS_IDS.includes(region)) {
    return "Visayas";
  } else if (MINDANAO_IDS.includes(region)) {
    return "Mindanao";
  }
};
export const locationFilter = async (island) => {
  const LUZON_IDS = [1, 2, 3, 4, 5, 14, 15];
  const VISAYAS_IDS = [6, 7, 8, 9];
  const MINDANAO_IDS = [10, 11, 12, 13, 16, 17];

  const regionsData = await regions().then((response) => {
    return response;
  });

  let filteredRegions = [];

  switch (island) {
    case "luzon":
      filteredRegions = regionsData.filter((region) =>
        LUZON_IDS.includes(region.id)
      );
      break;

    case "visayas":
      filteredRegions = regionsData.filter((region) =>
        VISAYAS_IDS.includes(region.id)
      );
      break;

    case "mindanao":
      filteredRegions = regionsData.filter((region) =>
        MINDANAO_IDS.includes(region.id)
      );
      break;

    default:
      // Handle an unexpected island string
      break;
  }

  const regionsWithFilteredProvinces = [];

  if (filteredRegions && filteredRegions.length > 0) {
    for (const region of filteredRegions) {
      const provinceData = await provinces(region.region_code).then(
        (response) => {
          return response;
        }
      );

      regionsWithFilteredProvinces.push({
        ...region,
        provinces: provinceData,
      });
    }
  }
  return regionsWithFilteredProvinces;
};
export const provinceFilter = async (island) => {
  const LUZON_IDS = [1, 2, 3, 4, 5, 14, 15];
  const VISAYAS_IDS = [6, 7, 8, 9];
  const MINDANAO_IDS = [10, 11, 12, 13, 16, 17];

  const regionsData = await regions().then((response) => {
    return response;
  });

  let filteredRegions = [];

  switch (island) {
    case "luzon":
      filteredRegions = regionsData.filter((region) =>
        LUZON_IDS.includes(region.id)
      );
      break;

    case "visayas":
      filteredRegions = regionsData.filter((region) =>
        VISAYAS_IDS.includes(region.id)
      );
      break;

    case "mindanao":
      filteredRegions = regionsData.filter((region) =>
        MINDANAO_IDS.includes(region.id)
      );
      break;

    default:
      // Handle an unexpected island string
      break;
  }

  const regionsWithFilteredProvinces = [];

  if (filteredRegions && filteredRegions.length > 0) {
    for (const region of filteredRegions) {
      const provinceData = await provinces(region.region_code).then(
        (response) => {
          return response;
        }
      );
      regionsWithFilteredProvinces.push(...provinceData);
    }
  }
  const filteredProvinces = regionsWithFilteredProvinces.map((province) => {
    return province.province_name;
  });

  return filteredProvinces;
};
export const tConvert = (time) => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};

export const getEventStatus = (eventDate) => {
  const currentDate = new Date();
  const targetDate = new Date(eventDate);

  const timeDiff = targetDate.getTime() - currentDate.getTime();
  const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));

  if (daysDiff === 0) {
    return "Happening Today";
  } else if (daysDiff === 1) {
    return "Happening Tomorrow";
  } else if (daysDiff > 1) {
    return `Happening in ${daysDiff} days`;
  } else {
    return "Completed";
  }
};

export const statusHandler = (booking) => {
  switch (booking) {
    case "0":
      return "For Confirmation";
    case "1":
      return "For Payment Reservation";
    case "2":
      return "Reserved Event";
    case "6":
      return "Successful Event";
    case "3":
      return "Cancelled by Client";
    case "4":
      return "Cancelled by Merchant";
    case "5":
      return "Cancelled by Admin";
    case "33":
    case "44":
      return "For Cancellation Approval";
    default:
      return "";
  }
};

export const statusHandlerColor = (booking) => {
  switch (booking) {
    case "0":
    case "7":
      return "text-orange-500";
    case "1":
      return "text-brown-500";

    case "8":
      return "text-green-500";
    case "2":
      return "text-primary-500";
    case "3":
    case "4":
    case "5":
      return "text-red-500";
    case "6":
      return "text-blue-500";
    case "33":
    case "44":
    case "55":
      return "text-orange-700";
    default:
      break;
  }
};
export const paymentStatusHandlerColor = (status) => {
  switch (status) {
    case "1":
      return "text-primary-500";
    case "3":
      return "text-green-500";
    default:
      return "text-gray-400";
  }
};
export const paymentStatusHandler = (status) => {
  // 0 - unpaid
  // 1 - reserved
  // 2 - partial
  // 3 - paid
  // 4 - refund request
  // 5 - refunded
  switch (status) {
    case "1":
      return "Reservation Payment";
    case "3":
      return "Confirmed";
    default:
      return "-";
  }
};

export const isValidUrl = (value) => {
  const urlRegex = /^(https?:\/\/.*\.(?:png|jpg|jpeg|gif|webp|svg))/i;
  return urlRegex.test(value);
};