import * as React from "react";
const ReviewBorder = () => {
  return (
    <svg width="100%" height="170" viewBox="0 0 334 179" fill="none">
      <rect
        x="0.5"
        y="20.5"
        width="333"
        height="158"
        rx="7.5"
        fill="url(#paint0_radial_1657_4120)"
      />
      <rect
        x="0.5"
        y="20.5"
        width="333"
        height="158"
        rx="7.5"
        stroke="url(#paint1_linear_1657_4120)"
      />
      <g filter="url(#filter0_d_1657_4120)">
        <mask
          id="mask0_1657_4120"
          maskUnits="userSpaceOnUse"
          x="146"
          y="0"
          width="41"
          height="41"
        >
          <rect x="146.5" y="0.5" width="40" height="40" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1657_4120)">
          <path
            d="M156.892 38.2771C157.488 38.7105 158.166 38.7988 158.868 38.5544L166.499 36.0245L174.129 38.5544C174.831 38.7988 175.51 38.7105 176.105 38.2771C176.69 37.8519 176.999 37.2587 176.999 36.5394V26.1466C178.034 24.9686 178.842 23.6311 179.423 22.1373C180.03 20.5765 180.332 18.9175 180.332 17.1644C180.332 13.3124 178.99 10.0316 176.311 7.3525C173.632 4.67344 170.351 3.33105 166.499 3.33105C162.647 3.33105 159.366 4.67344 156.687 7.3525C154.008 10.0316 152.666 13.3124 152.666 17.1644C152.666 18.9175 152.968 20.5765 153.575 22.1373C154.155 23.6311 154.964 24.9686 155.999 26.1466V36.5394C155.999 37.2587 156.308 37.8519 156.892 38.2771Z"
            fill="url(#paint2_linear_1657_4120)"
            stroke="url(#paint3_linear_1657_4120)"
          />
        </g>
      </g>
      <circle cx="166.5" cy="17" r="10" fill="url(#paint4_radial_1657_4120)" />
      <g filter="url(#filter1_d_1657_4120)">
        <mask
          id="mask1_1657_4120"
          maskUnits="userSpaceOnUse"
          x="159"
          y="9"
          width="15"
          height="15"
        >
          <rect x="159.5" y="9.5" width="14" height="14" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask1_1657_4120)">
          <path
            d="M171.75 14.1663C172.061 14.1663 172.333 14.2829 172.567 14.5163C172.8 14.7496 172.917 15.0218 172.917 15.3329V16.4996C172.917 16.5677 172.909 16.6406 172.895 16.7184C172.88 16.7961 172.858 16.8691 172.829 16.9371L171.079 21.0496C170.992 21.2441 170.846 21.4093 170.642 21.5454C170.437 21.6816 170.223 21.7496 170 21.7496H165.333C165.012 21.7496 164.738 21.6354 164.509 21.4069C164.281 21.1784 164.167 20.9038 164.167 20.5829V14.6475C164.167 14.492 164.198 14.3437 164.261 14.2027C164.324 14.0618 164.41 13.9378 164.517 13.8309L167.681 10.6809C167.827 10.5448 167.999 10.4621 168.199 10.4329C168.398 10.4038 168.59 10.4378 168.775 10.535C168.96 10.6323 169.093 10.7684 169.176 10.9434C169.259 11.1184 169.276 11.2982 169.227 11.4829L168.571 14.1663H171.75ZM161.833 21.7496C161.512 21.7496 161.238 21.6354 161.009 21.4069C160.781 21.1784 160.667 20.9038 160.667 20.5829V15.3329C160.667 15.0121 160.781 14.7375 161.009 14.509C161.238 14.2805 161.512 14.1663 161.833 14.1663C162.154 14.1663 162.429 14.2805 162.657 14.509C162.886 14.7375 163 15.0121 163 15.3329V20.5829C163 20.9038 162.886 21.1784 162.657 21.4069C162.429 21.6354 162.154 21.7496 161.833 21.7496Z"
            fill="#FFFBF3"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1657_4120"
          x="150.166"
          y="1.83105"
          width="32.6665"
          height="40.3574"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="multiply"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1657_4120"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1657_4120"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1657_4120"
          x="156.667"
          y="7.42285"
          width="20.25"
          height="19.3271"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.416667 0 0 0 0 0.273069 0 0 0 0 0.0503472 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1657_4120"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1657_4120"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_1657_4120"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(166.5 20.6625) rotate(90) scale(60.2875 208.019)"
        >
          <stop stop-color="#FFF0D7" />
          <stop offset="1" stop-color="white" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_1657_4120"
          x1="6.5"
          y1="28.5178"
          x2="350.698"
          y2="108.965"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9F7F4C" />
          <stop offset="0.486257" stop-color="#FFF2DC" />
          <stop offset="1" stop-color="#D1A763" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1657_4120"
          x1="155"
          y1="8.5"
          x2="176.5"
          y2="39.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C0934A" />
          <stop offset="0.313354" stop-color="#FFD694" />
          <stop offset="0.817174" stop-color="#8E6B32" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1657_4120"
          x1="175.428"
          y1="7.64244"
          x2="155.071"
          y2="35.4996"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D39A40" />
          <stop offset="0.49" stop-color="#E7DCCB" />
          <stop offset="1" stop-color="#D39A40" />
        </linearGradient>
        <radialGradient
          id="paint4_radial_1657_4120"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(160.429 8.71456) rotate(59.0362) scale(29.1548 26.892)"
        >
          <stop stop-color="#FFD38C" />
          <stop offset="0.788429" stop-color="#9E6D1E" />
        </radialGradient>
      </defs>
    </svg>
  );
};
export default ReviewBorder
