import { useState } from "react";
import { reviewService } from "../../../api/client/listing";
import { ModalHeader } from "../../common/ModalHeader";
import CheckSVG from "../../../assets/svg/CheckSVG";
import CancelSVG from "../../../assets/svg/CancelSVG";
import ReviewBorder from "../../../assets/svg/ReviewBorder";

import toast from "react-hot-toast";

const RecommendMerchant = ({
  onClose,
  serviceOffered,
  bookingCode,
  resetMessages,
  isMobile = false
}) => {
  const [about, setAbout] = useState("");
  const [isRecommended, setIsRecommended] = useState(true);

  const handleSubmit = async () => {
    const _requestData = {
      ribbon: isRecommended ? 1 : 0,
      booking_code: bookingCode,
      remarks: about,
    };
    const response = await reviewService(_requestData);
    if (response?.error === 0) {
      toast(response.msg);
      resetMessages();
      onClose();
    } else {
      toast(response.error_msg);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="bg-white rounded-3xl relative py-6 mx-4 w-430">
        <ModalHeader
          title={"Recommend"}
          onClick={() => {
            onClose();
          }}
          type={1}
        />
        <div className={`overflow-auto ${isMobile ? "h-80": "h-455"} px-5 gap-2 flex flex-col relative`}>
          {/* Merchant Info */}
          {isMobile ? <></>:
          <div className="flex relative w-full">
            <ReviewBorder />
            <div className="absolute inset-14  flex space-x-4">
              <img
                src={serviceOffered?.cover_img || ""}
                alt="Merchant Cover"
                className="w-24 h-24 rounded-lg object-cover"
              />
              <div className="space-y-1">
                <p className="text-base font-semibold">
                  {serviceOffered?.title}
                </p>
                <div
                  className="text-xs py-1 px-2 text-center text-gray-500 rounded-full inline-block"
                  style={{ backgroundColor: "#E1E8FF", width: "fit-content" }}
                >
                  {serviceOffered?.theme || "Any Event"}
                </div>

                <p className="text-xs text-gray-400">
                  {serviceOffered?.location}
                </p>
              </div>
            </div>
          </div>}

          {/* Recommendation Options */}
          <div className="mt-4 space-y-2">
            <p className="text-sm text-gray-600 mb-4">
              Give a Gold Ribbon and recommend this merchant.
            </p>
            <div className="flex space-x-2">
              <button
                className={`flex items-center w-full p-3 border rounded-full ${
                  isRecommended
                    ? "bg-primary-500 text-white"
                    : "bg-white border-primary-500 text-primary-500"
                }`}
                onClick={() => setIsRecommended(true)}
              >
                {isRecommended ? <CheckSVG /> : <CancelSVG />}{" "}
                <p className="pl-2">Yes</p>
              </button>
              <button
                className={`flex items-center w-full p-3 border rounded-full ${
                  !isRecommended
                    ? "bg-primary-500 text-white"
                    : "bg-white border-primary-500 text-primary-500"
                }`}
                onClick={() => setIsRecommended(false)}
              >
                {!isRecommended ? <CheckSVG /> : <CancelSVG />}{" "}
                <p className="pl-2">No</p>
              </button>
            </div>
          </div>

          {/* Remarks Section */}
          {isRecommended && (
            <div className="mt-4">
              <p className="text-sm text-gray-400 mb-2">
                Tell us, how did your event go with Anyaya Venue?
              </p>
              <textarea
                rows={3}
                autoFocus={true}
                value={about}
                onChange={(e) => setAbout(e.target.value)}
                className="resize-none border rounded-xl w-full p-4 text-sm"
              />
            </div>
          )}
        </div>

        <div className="mx-4 mt-4 flex space-x-2">
          <button
            className="flex-1 py-3 font-semibold text-sunrise-500 bg-white"
            onClick={onClose}
          >
            Do it later
          </button>
          <button
            className="flex-1 py-3 rounded-xl bg-primary-500 text-white font-semibold hover:opacity-90"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecommendMerchant;
