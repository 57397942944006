import * as React from "react";
const CheckSVG = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none">
      <g mask="url(#mask0_1632_4254)">
        <path
          d="M11.1 14.3L8.95 12.15C8.76667 11.9667 8.53333 11.875 8.25 11.875C7.96667 11.875 7.73333 11.9667 7.55 12.15C7.36667 12.3333 7.275 12.5667 7.275 12.85C7.275 13.1333 7.36667 13.3667 7.55 13.55L10.4 16.4C10.6 16.6 10.8333 16.7 11.1 16.7C11.3667 16.7 11.6 16.6 11.8 16.4L17.45 10.75C17.6333 10.5667 17.725 10.3333 17.725 10.05C17.725 9.76667 17.6333 9.53333 17.45 9.35C17.2667 9.16667 17.0333 9.075 16.75 9.075C16.4667 9.075 16.2333 9.16667 16.05 9.35L11.1 14.3ZM12.5 22.5C11.1167 22.5 9.81667 22.2375 8.6 21.7125C7.38333 21.1875 6.325 20.475 5.425 19.575C4.525 18.675 3.8125 17.6167 3.2875 16.4C2.7625 15.1833 2.5 13.8833 2.5 12.5C2.5 11.1167 2.7625 9.81667 3.2875 8.6C3.8125 7.38333 4.525 6.325 5.425 5.425C6.325 4.525 7.38333 3.8125 8.6 3.2875C9.81667 2.7625 11.1167 2.5 12.5 2.5C13.8833 2.5 15.1833 2.7625 16.4 3.2875C17.6167 3.8125 18.675 4.525 19.575 5.425C20.475 6.325 21.1875 7.38333 21.7125 8.6C22.2375 9.81667 22.5 11.1167 22.5 12.5C22.5 13.8833 22.2375 15.1833 21.7125 16.4C21.1875 17.6167 20.475 18.675 19.575 19.575C18.675 20.475 17.6167 21.1875 16.4 21.7125C15.1833 22.2375 13.8833 22.5 12.5 22.5Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
export default CheckSVG;
