import React, { useState } from "react";
import CloseIcon from "../../assets/svg/Close";
import step1Image from "../../assets/images/step1-client.png";
import step2Image from "../../assets/images/step2-client.png";
import step3Image from "../../assets/images/step3-client.png";
import step4Image from "../../assets/images/step4-client.png";
import step1ImageMerchant from "../../assets/images/step1-merchant.png";
import step2ImageMerchant from "../../assets/images/step2-merchant.png";
import step3ImageMerchant from "../../assets/images/step3-merchant.png";
import step4ImageMerchant from "../../assets/images/step4-merchant.png";

export const HowDoesItWorks = ({ handleClose, isMerchant }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const steps = isMerchant
    ? [
        { description: "Verify Business account", image: step1ImageMerchant },
        {
          description: "Provide Payout information",
          image: step2ImageMerchant,
        },
        { description: "Create Event Listing", image: step3ImageMerchant },
        { description: "Chat with Customers", image: step4ImageMerchant },
        { description: "Deliver service to customer", image: step4Image },
        { description: "Receive service payment", image: step3Image },
      ]
    : [
        { description: "Select an event listing", image: step1Image },
        {
          description: "Send a booking request to the merchant",
          image: step2Image,
        },
        { description: "Complete your payment", image: step3Image },
        { description: "Celebrate!", image: step4Image },
      ];

  const handleNext = () => {
    if (currentStep < steps.length - 1) setCurrentStep(currentStep + 1);
    else handleClose();
  };

  const handleBack = () => {
    if (currentStep > 0) setCurrentStep(currentStep - 1);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-500">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={handleClose}
      ></div>

      <div className="bg-white rounded-3xl relative py-6 mx-4 w-96">
        <div className="flex justify-between px-6 border-b pb-4">
          <div className="text-primary-600 text-lg font-semibold">
            How does it work?
          </div>
          <button
            className="text-gray-400 hover:text-gray-600"
            onClick={handleClose}
          >
            <CloseIcon className="w-4 h-4" />
          </button>
        </div>

        <div className="gap-y-2 flex flex-col mt-5 px-6 mb-2 text-center">
          <img
            src={steps[currentStep].image}
            alt={`Step ${currentStep + 1}`}
            className="w-full h-40 object-contain"
          />
          <div className="flex flex-col gap-1 mt-5">
            <div className="text-sm font-semibold text-sunrise-500">
              Step {currentStep + 1}
            </div>
            <div
              className="text-base font-semibold"
              style={{ color: "#374151" }}
            >
              {steps[currentStep].description}
            </div>
          </div>
        </div>

        <div className="flex px-6 gap-2 mt-12">
          {currentStep !== 0 ? (
            <button
              className="flex-1 py-2 text-primary-500 rounded-md"
              onClick={handleBack}
            >
              Back
            </button>
          ) : (
            <div className="flex-1"></div>
          )}

          <button
            className="flex-1 py-2 bg-primary-500 text-white rounded-md"
            onClick={handleNext}
          >
            {currentStep === steps.length - 1 ? "Got it!" : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};
