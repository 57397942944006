import React, { useEffect } from "react";
import { Route, BrowserRouter, Routes, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CLogged from "./components/client";
import ClientLanding from "./components/client/Landing";
import Topbar from "./components/common/Topbar";
import Protected from "./components/common/Auth/Protected";
import MerchantProtected from "./components/common/Auth/MerchantProtected";
import Category from "./components/client/Category/Category";
import Listing from "./components/client/Listing";
import Detail from "./components/client/Listing/Detail";
import { Toaster } from "react-hot-toast";
import Wishlists from "./components/client/Wishlist";
import Event from "./components/client/Event";
import MerchantLanding from "./components/merchant/Landing";
import TermsAndConditions from "./components/common/TermsAndConditions";
import PrivacyPolicy from "./components/common/PrivacyPolicy";
import { Error404 } from "./components/common/404";
import Support from "./components/common/Support";
import { Footer, FooterMobile } from "./components/common/Footer";
import Account from "./components/client/Account";
import PersonalInfo from "./components/client/Account/PersonalInfo";
import { WishlistModal } from "./components/client/Wishlist/WishlistModal";
import { Request } from "./components/client/Listing/Request";
import { WebSocketProvider } from "./components/common/WebSocket/WebSocketContext";
import LoginSecurity from "./components/client/Account/LoginSecurity";
import Notifications from "./components/client/Account/Notification";
import PrivacyAndSharing from "./components/client/Account/Privacy";
import EventDetails from "./components/client/Event/EventDetails";
import Reservations from "./components/client/Event/Reservations";
import GuestInbox from "./components/client/Inbox";
import { RequestUpdate } from "./components/client/Listing/RequestUpdate";
import SignUp from "./components/merchant/SignUp";
import MerchantHome from "./components/merchant";
import { Login } from "./components/common/Auth/Login";
import { OTP } from "./components/common/Auth/OTP";
import { CreateProfile } from "./components/common/Auth/CreateProfile";
import { MerchantCalendar } from "./components/merchant/MerchantCalendar";
import { MerchantInsights } from "./components/merchant/MerchantInsights";

import MerchantMenu from "./components/merchant/MerchantMenu";
import NewService from "./components/merchant/Services/NewService";
import MerchantInbox from "./components/merchant/Inbox";
import PaymentHistory from "./components/client/Account/PaymentHistory";
import Services from "./components/merchant/Services";
import ServiceDetails from "./components/merchant/Services/Details";
import Notification from "./components/client/Notification";
import ReviewMerchant from "./components/client/ReviewMerchant";
import { Soon } from "./components/client/Listing/Soon";
import LandingScreen from "./components/common/Landing";
import { Maintenance } from "./components/common/Maintenance";
const TLayout = ({ children }) => (
  <React.Fragment>
    <div id="routes" className="flex-grow">
      <Topbar />
      {children}
    </div>
    {/* <FooterMobile /> */}
    <Footer />
  </React.Fragment>
);

const Layout = ({ children }) => {
  return (
    <div id="routes" className="flex-grow">
      {children}
    </div>
  );
};
const RLayout = ({ children }) => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/client");
    }
  }, [isLoggedIn, navigate]);
  
  return (
    <div id="routes" className="flex-grow">
      {children}
    </div>
  );
};


const AppRouter = () => {
  const wishlistModal = useSelector((state) => state.listing.wishlistModal);
  const isSoon = useSelector((state) => state.listing.isSoon);

  const requestToBookModal = useSelector(
    (state) => state.listing.requestToBookModal
  );
  const loginModal = useSelector((state) => state.auth.loginModal);
  const OTPModal = useSelector((state) => state.auth.OTPModal);
  const isNewAccount = useSelector((state) => state.auth.isNewAccount);

  return (
    // <Maintenance />
    <CLogged>
      <Toaster position="top-right" reverseOrder={false} />
      <WebSocketProvider>
        <div className="text-gray-500  select-none text-base flex flex-col min-h-screen">
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <RLayout>
                    <LandingScreen />
                  </RLayout>
                }
              />

              <Route
                path="/client"
                element={
                  <Layout>
                    <ClientLanding />
                  </Layout>
                }
              />
              <Route
                path="/category"
                element={
                  <TLayout>
                    <Category />
                  </TLayout>
                }
              />

              <Route
                path="/category/:param/:paramid"
                element={
                  <TLayout>
                    <Listing />
                  </TLayout>
                }
              />

              <Route
                path="/category/:param/:paramid/:id"
                element={
                  <TLayout>
                    <Detail />
                  </TLayout>
                }
              />

              <Route
                path="/merchant"
                element={
                  <TLayout>
                    <MerchantLanding />
                  </TLayout>
                }
              />
              <Route
                path="/terms-and-conditions"
                element={
                  <TLayout>
                    <TermsAndConditions />
                  </TLayout>
                }
              />
              <Route
                path="/privacy-policy"
                element={
                  <TLayout>
                    <PrivacyPolicy />
                  </TLayout>
                }
              />
              <Route
                path="/support"
                element={
                  <TLayout>
                    <Support />
                  </TLayout>
                }
              />
              <Route
                path="*"
                element={
                  <TLayout>
                    <Error404 />
                  </TLayout>
                }
              />

              {/* protected */}

              <Route
                path="/request/:param/:paramid/:id"
                element={
                  <TLayout>
                    <Protected>
                      <Request />
                    </Protected>
                  </TLayout>
                }
              />

              <Route
                path="/updaterequest/:param/:paramid/:id/:booking"
                element={
                  <TLayout>
                    <Protected>
                      <RequestUpdate />
                    </Protected>
                  </TLayout>
                }
              />

              <Route
                path="/account"
                element={
                  <TLayout>
                    <Protected>
                      <Account />
                    </Protected>
                  </TLayout>
                }
              />

              <Route
                path="/account/login-and-security"
                element={
                  <TLayout>
                    <Protected>
                      <LoginSecurity />
                    </Protected>
                  </TLayout>
                }
              />

              <Route
                path="/notifications"
                element={
                  <TLayout>
                    <Protected>
                      <Notification />
                    </Protected>
                  </TLayout>
                }
              />

              <Route
                path="/account/notifications"
                element={
                  <TLayout>
                    <Protected>
                      <Notifications />
                    </Protected>
                  </TLayout>
                }
              />

              <Route
                path="/account/personal"
                element={
                  <TLayout>
                    <Protected>
                      <PersonalInfo />
                    </Protected>
                  </TLayout>
                }
              />
              <Route
                path="/account/payments"
                element={
                  <TLayout>
                    <Protected>
                      <PaymentHistory />
                    </Protected>
                  </TLayout>
                }
              />

              {/* <Route
            path="/account/login-and-security"
            element={
              <Protected>
                <LoginSecurity />
              </Protected>
            }
          />
          <Route
            path="/account/notifications"
            element={
              <Protected>
                <Notifications />
              </Protected>
            }
          />
           <Route
            path="/account/invite"
            element={
              <Protected>
                <ReferralCoupons />
              </Protected>
            }
          /> */}
              <Route
                path="/account/privacy-sharing"
                element={
                  <TLayout>
                    <Protected>
                      <PrivacyAndSharing />
                    </Protected>
                  </TLayout>
                }
              />

              <Route
                path="/wishlists"
                element={
                  <TLayout>
                    <Protected>
                      <Wishlists />
                    </Protected>
                  </TLayout>
                }
              />

              <Route
                path="/events"
                element={
                  <TLayout>
                    <Protected>
                      <Event />
                    </Protected>
                  </TLayout>
                }
              />
              <Route
                path="/events/:param"
                element={
                  <TLayout>
                    <Protected>
                      <EventDetails />
                    </Protected>
                  </TLayout>
                }
              />
              <Route
                path="/events/reservations"
                element={
                  <TLayout>
                    <Protected>
                      <Reservations />
                    </Protected>
                  </TLayout>
                }
              />

              <Route
                path="/client/inbox/:param"
                element={
                  <TLayout>
                    <Protected>
                      <GuestInbox />
                    </Protected>
                  </TLayout>
                }
              />
              <Route
                path="/review/:bookingCode/:merchantCode"
                element={
                  <TLayout>
                    <Protected>
                      <ReviewMerchant />
                    </Protected>
                  </TLayout>
                }
              />

              {/* merchant */}

              <Route
                path="/merchant/signup"
                element={
                  <TLayout>
                    <Protected>
                      <SignUp />
                    </Protected>
                  </TLayout>
                }
              />

              <Route
                path="/merchant/home"
                element={
                  <TLayout>
                    <MerchantProtected>
                      <MerchantHome />
                    </MerchantProtected>
                  </TLayout>
                }
              />

              <Route
                path="/merchant/calendar"
                element={
                  <TLayout>
                    <MerchantProtected>
                      <MerchantCalendar />
                    </MerchantProtected>
                  </TLayout>
                }
              />

              <Route
                path="/merchant/insights"
                element={
                  <TLayout>
                    <MerchantProtected>
                      <MerchantInsights />
                    </MerchantProtected>
                  </TLayout>
                }
              />

              <Route
                path="/merchant/menu"
                element={
                  <TLayout>
                    <MerchantProtected>
                      <MerchantMenu />
                    </MerchantProtected>
                  </TLayout>
                }
              />

              <Route
                path="/merchant/listing"
                element={
                  <TLayout>
                    <MerchantProtected>
                      <Services />
                    </MerchantProtected>
                  </TLayout>
                }
              />

              <Route
                path="/merchant/listing/:id"
                element={
                  <TLayout>
                    <MerchantProtected>
                      <ServiceDetails />
                    </MerchantProtected>
                  </TLayout>
                }
              />

              <Route
                path="/merchant/listing/new"
                element={
                  <TLayout>
                    <MerchantProtected>
                      <NewService />
                    </MerchantProtected>
                  </TLayout>
                }
              />

              <Route
                path="/merchant/inbox/:param"
                element={
                  <TLayout>
                    <MerchantProtected>
                      <MerchantInbox />
                    </MerchantProtected>
                  </TLayout>
                }
              />
            </Routes>
            {loginModal && <Login />}

            {OTPModal && <OTP />}
            {isNewAccount && <CreateProfile />}
            {wishlistModal && <WishlistModal />}
            {isSoon && <Soon />}

            {/* {requestToBookModal && <Request />} */}
          </BrowserRouter>
        </div>
      </WebSocketProvider>
    </CLogged>
  );
};

export default AppRouter;
