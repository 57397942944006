import React, { useEffect, useState } from "react";
import { PrimaryButton } from "../../common/PrimaryButton";
import { ReportListing } from "../Listing/Report";
import { BookingDetails } from "./BookingDetails";
import { useLocation } from "react-router-dom";
import Star from "../../../assets/svg/Star";
import ContentLoader from "react-content-loader";
import { config } from "../../../utils/config";
import {
  cancelReservation,
  paymentReservation,
} from "../../../api/client/listing";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Back from "../../../assets/svg/Back";
import dayjs from "dayjs";
import { PaymentSchedule } from "../Payment/PaymentSchedule";
import { ProfileDetails } from "./ProfileDetails";
import Loader from "../../common/Loader";
import { CancelBooking } from "../../common/CancelBooking";
import { Modal } from "../../common/Modal";
import { ORImages } from "./ORImages";
import { updateIsPaid } from "../../../store/slicers/listingSlice";
import { useDispatch, useSelector } from "react-redux";
import { MerchantBank } from "../Payment/MerchantBank";
import InboxPhotos from "../../merchant/Inbox/Photos";
import { ChatImages } from "./ChatImages";
import RecommendMerchant from "./RecommendMerchant";
const DetailsPage = ({
  serviceOffered,
  bookingCode,
  resetMessages,
  paymentSchedule,
  setDetailsOpen,
  bookingReceipt,
  chatDetails,
  chatImages,
  clientChatImages,
}) => {
  const dispatch = useDispatch();
  const isPaid = useSelector((state) => state.listing.isPaid);
  const location = useLocation();
  const [showReport, setShowReport] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const navigate = useNavigate();
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [amountToBePaid, setAmountToBePaid] = useState("");
  const [paymentCode, setPaymentCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [showMerchantBank, setShowMerchantBank] = useState(false);
  const [successCancel, setSuccessCancel] = useState(false);
  const [showReceipts, setShowReceipts] = useState(false);
  const [showRecommend, setShowRecommend] = useState(false);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showImages, setShowImages] = useState(false);
  const [showUploadedImage, setShowUploadedImage] = useState(false);

  const handleUpdateRequest = async (data) => {
    window.close();
    window.open(
      config.ANYAYA_URL +
        `/updaterequest/${serviceOffered.service_type_code}/${serviceOffered.service_name}/${serviceOffered.service_code}/${bookingCode}?start_date=${serviceOffered?.start_date}&end_date=${serviceOffered?.end_date}`,
      "_blank"
    );
  };

  const handleCancelRequest = async (reason) => {
    setIsCancelLoading(true);
    const _requestData = {
      booking_code: bookingCode,
      reason,
    };
    try {
      const response = await cancelReservation(_requestData);
      if (response.error === 0) {
        // toast(response.msg);
        setSuccessCancel(true);
        resetMessages();
        setIsCancelLoading(false);
        setShowCancel(false);
        // setListings([]);
        // setIsLoading(false);
        // setLastPage(false);
        // setPage(1);

        // if (!bLastPage) {
        //   handleListings();
        // }
      } else {
        setSuccessCancel(false);
        setIsCancelLoading(false);
        toast(response.error_msg);
      }
    } catch (error) {
      setSuccessCancel(false);
      setIsCancelLoading(false);
      console.log("Error:", error);
    }
  };
  const handlePayment = async (paymentCode) => {
    setIsLoading(true);
    const _requestData = {
      payment_code: paymentCode,
      flag: "express_payment",
    };
    try {
      const response = await paymentReservation(_requestData);
      if (response.error === 0) {
        setIsLoading(false);
        window.location.href = response.checkout.redirectUrl;
      } else {
        toast(response.error_msg);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast(error);
    }
  };

  const handleRateMerchant = async () => {};

  const isPaymentSubjectForVerification =
    (serviceOffered?.booking_status === "1" &&
      paymentSchedule[0]?.status === "1") ||
    (serviceOffered?.booking_status === "2" &&
      paymentSchedule[1]?.status === "1") ||
    (serviceOffered?.booking_status === "2" &&
      paymentSchedule[2]?.status === "1");

  const isPaymentDeclined =
    (serviceOffered?.booking_status === "1" &&
      paymentSchedule[0]?.status === "3") ||
    (serviceOffered?.booking_status === "2" &&
      paymentSchedule[1]?.status === "3") ||
    (serviceOffered?.booking_status === "2" &&
      paymentSchedule[2]?.status === "3");

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.classList.remove("no-scroll-body");
    document.getElementById("routes")?.classList.remove("inbox");
    return () => {
      window.scrollTo(0, 0);
      document.body.classList.add("no-scroll-body");
      document.getElementById("routes")?.classList.add("inbox");
    };
  }, []);

  return (
    <div className="overflow-scroll flex flex-col justify-between h-full w-full">
      <div
        className="flex flex-row py-5 border-b mb-5 px-2"
        onClick={() => {
          setDetailsOpen();
        }}
      >
        <div>
          <Back className="w-6 h-6" />
        </div>
        <div className="text-base font-semibold">Chat</div>
      </div>
      {serviceOffered ? (
        <div className="flex flex-col px-5">
          <div className="relative h-56 w-full">
            <img
              alt="gallery"
              draggable="false"
              className="h-full w-full border rounded-3xl bg-no-repeat bg-center bg-contain"
              src={serviceOffered?.cover_img}
            />
          </div>

          <div className="block">
            {/* <div className="w-full h-56 bg-sunrise-500 rounded-3xl"></div> */}
            <div className="flex flex-row justify-between">
              <div className="text-primary-500 font-semibold text-base mt-5">
                {serviceOffered?.title}{" "}
                <span className="text-sm text-gray-500">by</span>{" "}
                {serviceOffered?.merchant_name}
              </div>
              {Number(serviceOffered.review_count) > 0 && (
                <div className="flex flex-row font-semibold mt-1 items-center">
                  <div className="w-5 h-5 mr-2">
                    <div className="ribbon w-full h-full bg-no-repeat bg-contain"></div>
                  </div>
                  {serviceOffered.review_count}
                </div>
              )}
            </div>
            <div className="text-xs text-gray-400">
              {serviceOffered?.description}
            </div>

            {serviceOffered.booking_status === "3" ||
            serviceOffered.booking_status === "4" ? (
              <div className="mt-5">
                <div className="text-sm font-semibold">Request cancelled</div>
                <div
                  onClick={() => navigate("/category")}
                  className="cursor-pointer border border-primary-500 p-3 rounded-md mt-4"
                >
                  <p className="text-xs text-primary-500">Keep Searching</p>
                </div>
              </div>
            ) : null}

            {serviceOffered.booking_status === "5" && (
              <div className="mt-5">
                <div className="text-sm font-semibold">Request declined</div>
                <div
                  onClick={() => navigate("/category")}
                  className="cursor-pointer border border-primary-500 p-3 rounded-md mt-4"
                >
                  <p className="text-xs text-primary-500">Keep Searching</p>
                </div>
              </div>
            )}

            {["0", "1", "2", "6", "7"].includes(
              serviceOffered.booking_status
            ) && (
              <div
                className="flex flex-row mt-5 justify-between cursor-pointer"
                onClick={() => setShowDetails(true)}
              >
                <div>
                  <div className="font-semibold text-sm">Booking Details</div>
                  <div className="text-xs">
                    {serviceOffered?.title} by {serviceOffered?.merchant_name}
                  </div>
                </div>
                <div>{">"}</div>
              </div>
            )}

            {bookingReceipt?.length > 0 && (
              <div
                onClick={() => setShowReceipts(true)}
                className="cursor-pointer border border-orange-500 p-3 rounded-md mt-4"
              >
                <p className="text-xs text-orange-500">View Receipts</p>
              </div>
            )}

            {serviceOffered?.is_reviewed === "0" &&
              ["6"].includes(serviceOffered.booking_status) && (
                <div
                  onClick={() => handleRateMerchant()}
                  className="cursor-pointer border border-primary-500 p-3 rounded-md mt-4"
                >
                  <p className="text-xs text-primary-500">Rate this Merchant</p>
                </div>
              )}

            {serviceOffered.booking_status === "3" ||
            serviceOffered.booking_status === "4" ||
            serviceOffered.booking_status === "5" ? (
              <></>
            ) : (
              <>
                <div
                  onClick={() => setShowUploadedImage(true)}
                  className="cursor-pointer border border-primary-500 p-3 rounded-md mt-4"
                >
                  <p className="text-xs text-primary-500">Upload Photo</p>
                </div>
                <div
                  onClick={() => setShowImages(true)}
                  className="cursor-pointer border border-sunrise-500 p-3 rounded-md mt-4"
                >
                  <p className="text-xs text-sunrise-500">
                    See Merchant Upload
                  </p>
                </div>
              </>
            )}
            {/* {serviceOffered?.happening_today === "1"
              ? null
              : ["0", "1", "2"].includes(serviceOffered.booking_status) && (
                  <div
                    onClick={() => handleUpdateRequest()}
                    className="cursor-pointer border border-primary-500 p-3 rounded-md mt-4"
                  >
                    <p className="text-xs text-primary-500">
                      Change Event Details
                    </p>
                  </div>
                )} */}
            {serviceOffered?.can_cancel === 1
              ? null
              : ["0", "1", "2", "7"].includes(
                  serviceOffered?.booking_status
                ) && (
                  <div
                    onClick={() => {
                      setShowCancel(true);
                    }}
                    className="cursor-pointer border border-red-500 p-3 rounded-md mt-4"
                  >
                    {isCancelLoading ? (
                      <Loader borderColor="border-sunrise-500" />
                    ) : (
                      <p className="text-xs text-red-500">Cancel</p>
                    )}
                  </div>
                )}
            {serviceOffered?.is_reviewed === 0
              ? null
              : ["6"].includes(serviceOffered?.booking_status) && (
                  <div
                    onClick={() => {
                      setShowRecommend(true);
                    }}
                    className="cursor-pointer border border-primary-500 p-3 rounded-md mt-4"
                  >
                    <p className="text-xs text-primary-500">
                      Recommend this Merchant
                    </p>
                  </div>
                )}

            <div>
              {["1", "2", "7"].includes(serviceOffered.booking_status) && (
                <div className="border-t my-5" />
              )}
              {/* <div
                onClick={() => {
                  setShowMerchantBank(true);
                }}
                className="cursor-pointer border border-gray-400 p-3 rounded-md mt-4"
              >
                <p className="text-xs text-gray-500">
                  Payment Details of Merchant
                </p>
              </div> */}

              {/* {isPaymentDeclined && (
                <div className="mt-4">
                  <p className="text-sm text-red-500">
                    We're sorry, but your payment was declined. Please
                    double-check your payment information and resubmit the
                    payment to complete your transaction. If the issue persists,
                    contact customer support for further assistance.
                  </p>
                </div>
              )}

              {isPaymentSubjectForVerification && (
                <div className="mt-4">
                  <p className="text-sm text-gray-500">
                    Your payment is now subject for verification. Please wait
                    for confirmation
                  </p>
                </div>
              )}
              {["44", "33"].includes(serviceOffered?.booking_status) && (
                <div className="mt-4">
                  <p className="text-sm text-gray-500">
                    Cancellation request is now subject for verification. Please
                    wait for confirmation.
                  </p>
                </div>
              )}
              {["7"].includes(serviceOffered.booking_status) && (
                <div className="mt-4">
                  <p className="text-sm text-gray-500">
                    Your request is now subject for approval. Please wait for
                    merchant's confirmation.
                  </p>
                </div>
              )} */}
              {!isPaymentSubjectForVerification &&
                paymentSchedule.map((data, key) => {
                  return (
                    <>
                      {/* {["1"].includes(serviceOffered?.booking_status) &&
                        data?.is_reservation === "1" && (
                          <div
                            key={key}
                            onClick={() => {
                              !isLoading &&
                                setAmountToBePaid(data?.amount_paid);
                              setPaymentCode(data?.payment_code);
                              setShowPaymentDetails(true);
                            }}
                            className="cursor-pointer border border-gray-400 p-3 rounded-md mt-4"
                          >
                            <p className="text-sm text-gray-500">
                              Pay to reserve date{" "}
                            </p>
                            {isLoading ? (
                              <Loader borderColor="border-primary-500" />
                            ) : (
                              <p className="text-gray-400 text-xs">
                                Pay the reservation fee (PHP{" "}
                                {serviceOffered?.reservation_fee})
                                {serviceOffered?.service_fee &&
                                  ` and Service Fee (PHP ${serviceOffered?.service_fee})`}
                              </p>
                            )}
                          </div>
                        )} */}
                      {/* {serviceOffered?.booking_status === "2" &&
                        serviceOffered?.payment_status === "1" &&
                        data?.is_reservation === "2" && (
                          <div
                            key={key}
                            onClick={() => {
                              setAmountToBePaid(data?.amount_paid);
                              setPaymentCode(data?.payment_code);
                              setShowPaymentDetails(true);
                            }}
                            className="cursor-pointer border border-gray-400 p-3 rounded-md mt-4"
                          >
                            <p className="text-sm text-gray-500">Pay in full</p>
                            <p className="text-gray-400 text-xs">
                              (PHP {data?.amount_paid}) before{" "}
                              {dayjs(data?.payment_date).format("MM/DD/YYYY")}
                            </p>
                          </div>
                        )} */}

                      {["1"].includes(serviceOffered?.booking_status) &&
                        data?.is_reservation === "3" && (
                          <div
                            key={key}
                            onClick={() => {
                              !isLoading &&
                                setAmountToBePaid(data?.amount_paid);
                              setPaymentCode(data?.payment_code);
                              setShowPaymentDetails(true);
                            }}
                            className="cursor-pointer border border-gray-400 p-3 rounded-md mt-4"
                          >
                            <p className="text-sm text-gray-500">
                              Pay PHP {data?.amount_paid}
                            </p>
                            {isLoading ? (
                              <Loader borderColor="border-primary-500" />
                            ) : (
                              <p className="text-gray-400 text-xs">
                                Pay the
                                {serviceOffered?.service_fee &&
                                  ` Service Fee (PHP ${serviceOffered?.service_fee})`}
                                {serviceOffered?.service_fee &&
                                  ` and Contract Price (PHP ${serviceOffered?.service_price})`}
                              </p>
                            )}
                          </div>
                        )}
                    </>
                  );
                })}

              {/* {!isPaymentSubjectForVerification &&
                ["1"].includes(serviceOffered.booking_status) &&
                ["0", "3"].includes(paymentSchedule[0]?.status) && (
                  <div
                    onClick={() => {
                      !isLoading &&
                        handlePayment(paymentSchedule[0]["payment_code"]);
                    }}
                    className="cursor-pointer border border-gray-400 p-3 rounded-md mt-4"
                  >
                    <p className="text-sm text-gray-500">
                      Pay to reserve date{" "}
                    </p>
                    {isLoading ? (
                      <Loader borderColor="border-primary-500" />
                    ) : (
                      <p className="text-gray-400 text-xs">
                        Pay the reservation fee (PHP{" "}
                        {serviceOffered?.reservation_fee})
                        {serviceOffered?.service_fee &&
                          `and Service Fee (PHP ${serviceOffered?.service_fee})`}
                      </p>
                    )}
                  </div>
                )}

              {!isPaymentSubjectForVerification &&
                ["2"].includes(serviceOffered.booking_status) &&
                ["1"].includes(serviceOffered.payment_status) && (
                  <div
                    onClick={() => {
                      setAmountToBePaid(paymentSchedule[1].amount_paid);
                      setPaymentCode(paymentSchedule[1].payment_code);
                      setShowPaymentDetails(true);
                    }}
                    className="cursor-pointer border border-gray-400 p-3 rounded-md mt-4"
                  >
                    <p className="text-sm text-gray-500">Pay 50%</p>
                    <p className="text-gray-400 text-xs">
                      ({paymentSchedule[1].amount_paid} PHP) before{" "}
                      {dayjs(paymentSchedule[1].payment_date).format(
                        "MM/DD/YYYY"
                      )}
                    </p>
                  </div>
                )}

              {!isPaymentSubjectForVerification &&
                ["2"].includes(serviceOffered.booking_status) &&
                serviceOffered.payment_status !== "3" && (
                  <div
                    onClick={() => {
                      // 0 - pending payment
                      // 1 - for checking
                      // 2 - payment posted
                      // 3 - decline payment
                      setAmountToBePaid(paymentSchedule[2].amount_paid);
                      setPaymentCode(paymentSchedule[2].payment_code);
                      setShowPaymentDetails(true);
                    }}
                    className="cursor-pointer border border-gray-400 p-3 rounded-md mt-4"
                  >
                    <p className="text-sm text-gray-500"> Full Payment</p>
                    <p className="text-gray-400 text-xs">
                      Pay all before{" "}
                      {dayjs(paymentSchedule[2].payment_date).format(
                        "MM/DD/YYYY"
                      )}{" "}
                      or Pay Now
                    </p>
                  </div>
                )} */}
            </div>
          </div>
        </div>
      ) : (
        <div className="block">
          <ContentLoader
            speed={2}
            width={4000}
            height={460}
            viewBox="0 0 4000 460"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="3" y="211" rx="2" ry="2" width="237" height="17" />
            <rect x="3" y="29" rx="2" ry="2" width="314" height="164" />
            <rect x="3" y="344" rx="0" ry="0" width="0" height="6" />
            <rect x="3" y="319" rx="0" ry="0" width="301" height="105" />
            <rect x="3" y="236" rx="2" ry="2" width="237" height="17" />
            <rect x="3" y="267" rx="2" ry="2" width="237" height="33" />
          </ContentLoader>
        </div>
      )}

      <div className="pb-28 block px-5">
        <div className="py-5 items-center">
          <div className="flex flex-row mt-2 justify-between">
            <div className="w-12 h-12 mr-5">
              <div
                className="icon-anyaya h-full w-full "
                style={{ backgroundPositionY: "center" }}
              />
            </div>
            <div className="text-xs w-60 italic">
              To avoid a scam, never communicate outside of the Anyaya Website.
            </div>
          </div>
          <div
            className="flex flex-row text-xs mt-8 font-light w-full underline cursor-pointer"
            onClick={() => setShowReport(true)}
          >
            Report Merchant
            <div className="w-4 h-4 ml-1">
              <div className="report w-full h-full bg-no-repeat bg-contain"></div>
            </div>
          </div>
        </div>
      </div>

      {showReceipts && (
        <ORImages
          setShowReceipts={() => setShowReceipts(false)}
          receipts={bookingReceipt}
        />
      )}

      {showReport && (
        <ReportListing
          onClose={() => setShowReport(false)}
          type="merchant"
          id={serviceOffered?.merchant_code}
        />
      )}
      {showDetails && (
        <BookingDetails
          serviceOffered={serviceOffered}
          handleOnClose={() => setShowDetails(false)}
          id={bookingCode}
        />
      )}
      {showPaymentDetails && (
        <PaymentSchedule
          expressPayment={chatDetails?.express_payment}
          amountToBePaid={amountToBePaid}
          location="inbox"
          bookingCode={bookingCode}
          refreshListing={() => resetMessages()}
          onClose={() => setShowPaymentDetails(false)}
          paymentCode={paymentCode}
          setErrorx={(val) => {
            setError(val);
          }}
          setSuccess={(val) => {
            setSuccess(val);
          }}
        />
      )}
      {showProfile && (
        <ProfileDetails
          accountCode={serviceOffered?.merchant_code}
          handleOnClose={() => setShowProfile(false)}
        />
      )}
      {showCancel && (
        <CancelBooking
          onClose={() => setShowCancel(false)}
          handleCancel={(reason) => handleCancelRequest(reason)}
          isLoading={isCancelLoading}
        />
      )}

      {successCancel && (
        <Modal
          onClose={() => setSuccessCancel(false)}
          title={"Cancelled"}
          description={
            "You cancelled your requested/reserved/confirmed booking. This may have an effect on your payment refunds. Please give our team 24 hours to review this cancellation."
          }
        />
      )}
      {(success || error) && (
        <Modal
          onClose={() => {
            setError("");
            setSuccess("");
          }}
          title={error ? "Error" : ""}
          description={success || error}
        />
      )}
      {isPaid && (
        <Modal
          onClose={() => {
            dispatch(updateIsPaid(false));
          }}
          title={""}
          description="Service cancellation after full payment is not permitted. Please reach out to us if you still need to cancel. Thank you.
          "
        />
      )}

      {showMerchantBank && (
        <MerchantBank
          onClose={() => setShowMerchantBank(false)}
          bookingCode={bookingCode}
        />
      )}

      {showUploadedImage && (
        <InboxPhotos
          handleOnClose={() => setShowUploadedImage(false)}
          uploadedPhotos={clientChatImages}
          id={bookingCode}
          location="client"
          resetMessages={() => resetMessages()}
        />
      )}
      {showImages && (
        <ChatImages
          setShowImages={() => setShowImages(false)}
          header="Merchant Upload"
          images={chatImages}
        />
      )}
      {showRecommend && (
        <RecommendMerchant
          onClose={() => setShowRecommend(false)}
          bookingCode={bookingCode}
          serviceOffered={serviceOffered ?? {}}
          resetMessages={() => resetMessages()}
          isMobile={true}
        />
      )}
    </div>
  );
};

export default DetailsPage;
