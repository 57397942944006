import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-multi-date-picker";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { updateMultipleFields } from "../../../store/slicers/listingSlice";
import Loader from "../../common/Loader";
import {
  reserve,
  updateReservation,
  viewListing,
} from "../../../api/client/listing";
import { Breadcrumbs } from "../../common/Breadcrumbs";
import Star from "../../../assets/svg/Star";
import MetaTags from "../../common/MetaTags";
import Error from "../../../assets/svg/Error";
import { tConvert } from "../../../utils/common";
import Helmet from "react-helmet";
const formatDateRange = (dateRange) => {
  let startDate, endDate;

  if (Array.isArray(dateRange)) {
    if (dateRange.length >= 2) {
      startDate = dayjs(dateRange[0]);
      endDate = dayjs(dateRange[1]);
    } else {
      return "Invalid array length";
    }
  } else if (typeof dateRange === "string") {
    if (!dateRange.includes(" ~ ")) {
      return "-";
    }

    const [startStr, endStr] = dateRange.split(" ~ ");
    startDate = dayjs(startStr);
    endDate = dayjs(endStr);
  } else {
    return "Invalid input type";
  }

  if (!startDate.isValid() || !endDate.isValid()) {
    return "Invalid date format";
  }

  const formattedStartDate = startDate.format("MMMM D, YYYY");
  const formattedEndDate = endDate.format("MMMM D, YYYY");

  if (startDate.isSame(endDate, "day")) {
    return formattedStartDate;
  }

  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const RequestUpdate = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState("");
  const [date, setDate] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [venue, setVenue] = useState("");
  const [pax, setPax] = useState("");
  const [remarks, setRemarks] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const pathnameParts = location.pathname.split("/");
  const listingId = pathnameParts[pathnameParts.length - 2];
  const bookingCode = pathnameParts[pathnameParts.length - 1];
  const dateBlocked = useSelector((state) => state.listing.serviceCalendar);

  const serviceOffered = useSelector((state) => state.listing.serviceOffered);
  const [isPricingLoading, setPricingLoading] = useState(false);
  const servicePricing = useSelector((state) => state.listing.servicePricing);

  const handleInit = async (init, start, end) => {
    try {
      const _requestData = {
        service_code: listingId,
        booking_code: bookingCode,
        start_date: start,
        end_date: end,
        flag: "init",
      };
      const response = await updateReservation(_requestData);
      if (response.error === 0) {
        const data = response.data;
        if (init) {
          setVenue(data.reservation.location);
          setPax(data.reservation.pax);
          setRemarks(data.reservation.remarks);
          setStartTime(dayjs(data.reservation.start_date).format("HH:mm"));
          setEndTime(dayjs(data.reservation.end_date).format("HH:mm"));

          const startDateParam = dayjs(data.reservation.start_date).format(
            "YYYY-MM-DD"
          );
          const endDateParam = dayjs(data.reservation.end_date).format(
            "YYYY-MM-DD"
          );

          setStartDate(startDateParam);
          setEndDate(endDateParam);

          dispatch(
            updateMultipleFields({
              selectedDate: {
                startDate: startDateParam,
                endDate: endDateParam,
              },
            })
          );

          setDate(startDateParam.concat(",").concat(endDateParam));
        }

        dispatch(
          updateMultipleFields({
            serviceCalendar: data.service_calendar,
            serviceOffered: data.service_offered,
            servicePricing: data.service_pricing,
            servicePhotos: [],
            serviceReviews: [],
            eventHighlights: [],
          })
        );
        setPricingLoading(false);
        setIsLoading(false);
      } else {
        // window.close();
      }
    } catch (error) {
      // window.close();
      console.log(error);
    }
  };

  useEffect(() => {
    const dates = date.split(",");
    const defaultDate = { startDate: "", endDate: "" };
    if (dates.length > 1) {
      if (dates[1] === defaultDate["endDate"]) {
        setPricingLoading(true);
      } else {
        handleInit(false, dates[0], dates[1]);
      }
    } else {
      setPricingLoading(true);
    }
  }, [date]);

  useEffect(() => {
    handleInit(true);
  }, []);

  const dispatch = useDispatch();

  const handleRequest = async () => {
    setSubmitLoading(true);

    let formattedStart = startDate;
    let formattedEnd = endDate;
    if (date.includes(",")) {
      const dateSplit = date.split(",");

      const startCleaned = dateSplit[0].replace(/%/g, " ");
      formattedStart = dayjs(startCleaned).format("YYYY-MM-D");

      const endCleaned = dateSplit[1].replace(/%/g, " ");
      formattedEnd = dayjs(endCleaned).format("YYYY-MM-D");
    }

    const postData = {
      booking_code: bookingCode,
      start_date: formattedStart + " " + tConvert(startTime),
      end_date: formattedEnd + " " + tConvert(endTime),
      location: venue,
      pax: pax,
      remarks: remarks,
      flag: "save",
    };
    try {
      const response = await updateReservation(postData);
      if (response.error === 0) {
        navigate("/client/inbox/" + bookingCode);
      } else {
        setError(response.error_msg);
      }
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  return (
    !isLoading && (
      <div className="px-5  align-center items-center mt-5">
        <Breadcrumbs
          listing={true}
          location={`${serviceOffered?.title}/update-request`}
        />
        <Helmet>
          <MetaTags
            title={"Update request - " + serviceOffered.title}
            description={serviceOffered.about}
            keywords={
              serviceOffered.full_name +
              " " +
              serviceOffered.municipality +
              " " +
              serviceOffered.province
            }
            imageUrl={serviceOffered.cover_img}
          />
        </Helmet>
        <div className="text-2xl font-semibold text-black">Update Request</div>

        <div className="flex flex-wrap mt-5 gap-10">
          <div className="flex flex-col w-full md:w-1/3">
            <div>
              <div className="w-full  items-center p-2 border mr-2 rounded-xl text-sm">
                <label
                  htmlFor="eventType"
                  className="px-2 text-xs text-gray-400"
                >
                  Type of Event
                </label>
                <div className="flex">
                  <input
                    autoFocus={true}
                    className="resize-none border-none px-2 outline-none w-full bg-transparent"
                    id="eventType"
                    type="text"
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row mt-2">
              <div className="w-full items-center p-2 border  rounded-xl text-sm">
                <label htmlFor="date" className="px-2 text-xs text-gray-400">
                  Date
                </label>
                <div className="flex">
                  <DatePicker
                    className="purple"
                    range
                    value={date.split(",")}
                    format="YYYY-MM-DD"
                    render={(value, openCalendar) => {
                      return (
                        <div id="date" onClick={openCalendar}>
                          {value === "" ? (
                            <p className="px-2 w-full text-gray-400">Date</p>
                          ) : (
                            <p className="px-2 w-full">
                              {formatDateRange(value)}
                            </p>
                          )}
                        </div>
                      );
                    }}
                    minDate={dayjs().toDate()}
                    onChange={(date) => {
                      const dates = date?.toString().split(",").map(dayjs);

                      if (dates) {
                        setStartDate(dates[0]);
                        setEndDate(dates[1]);
                      }
                      if (date?.toString().includes(",")) {
                        setDate(date?.toString());
                      } else {
                        setDate(date?.toString() + ",");
                      }
                    }}
                    mapDays={({ date }) => {
                      let formattedDay = dayjs(date.toDate()).format(
                        "YYYY-MM-DD"
                      );

                      if (dateBlocked.includes(formattedDay)) {
                        return {
                          className: "highlight blocked-date line-through",
                          disabled: true,
                        };
                      }

                      if (startDate) {
                        if (
                          dayjs(formattedDay).diff(startDate, "day") < 0 &&
                          !endDate
                        ) {
                          return {
                            className: "highlight blocked-date line-through",
                            disabled: true,
                          };
                        }
                        if (dayjs(formattedDay).diff(startDate, "day") > 0) {
                          const blocked = [];
                          dateBlocked.map((data) => {
                            if (dayjs(data).diff(startDate, "day") > 0) {
                              blocked.push(data);
                            }
                          });
                          if (
                            dayjs(blocked[0]).isAfter(startDate, "day") &&
                            dayjs(blocked[0]).isBefore(formattedDay, "day") &&
                            !endDate
                          ) {
                            return {
                              className: "highlight blocked-date line-through",
                              disabled: true,
                            };
                          }
                        }
                      }

                      if (endDate) {
                        return { className: "", disabled: false };
                      }
                    }}
                    numberOfMonths={1}
                    headerOrder={["MONTH_YEAR", "LEFT_BUTTON", "RIGHT_BUTTON"]}
                  />
                </div>
              </div>
            </div>
            <div className="flex gap-x-2 flex-row mt-2">
              <div className="w-full  items-center p-2 border rounded-xl text-sm">
                <label
                  htmlFor="startTime"
                  className="px-2 text-xs text-gray-400"
                >
                  Start Time
                </label>
                <div className="flex">
                  <input
                    className="resize-none border-none px-2 outline-none w-full bg-transparent"
                    id="startTime"
                    type="time"
                    placeholder="5:00 AM"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full  items-center p-2 border rounded-xl text-sm">
                <label htmlFor="endTime" className="px-2 text-xs text-gray-400">
                  End Time
                </label>
                <div className="flex">
                  <input
                    className="resize-none border-none px-2 outline-none w-full bg-transparent"
                    id="endTime"
                    type="time"
                    placeholder="8:00 PM"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-2">
              <div className="w-full  items-center p-2 border mr-2 rounded-xl text-sm">
                <label htmlFor="venue" className="px-2 text-xs text-gray-400">
                  Venue
                </label>
                <div className="flex">
                  <input
                    className="resize-none border-none px-2 outline-none w-full bg-transparent"
                    id="venue"
                    type="text"
                    value={venue}
                    onChange={(e) => setVenue(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="mt-2">
              <div className="w-full  items-center p-2 border mr-2 rounded-xl text-sm">
                <label htmlFor="pax" className="px-2 text-xs text-gray-400">
                  Number of Guest/s
                </label>
                <div className="flex">
                  <input
                    className="resize-none border-none px-2 outline-none w-full bg-transparent"
                    id="pax"
                    type="number"
                    value={pax}
                    onChange={(e) => setPax(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {error && (
              <div className="text-xs mt-2 text-red-500 flex flex-row item-center">
                <Error className="h-3 w-3 mr-1 self-center" />
                {error}
              </div>
            )}

            <div className="w-60">
              <button
                className="mt-4 bg-primary-500 px-10 w-full text-white font-semibold py-4 rounded-xl hover:opacity-90"
                onClick={isSubmitLoading ? () => {} : handleRequest}
              >
                {isSubmitLoading ? <Loader /> : <p className="text-sm">Send</p>}
              </button>
            </div>
          </div>

          <div className="">
            <div className="sticky">
              <div className="border-none lg:border lg:border-primary-500 rounded-3xl p-2 bg-white">
                <div className="flex flex-row pb-5">
                  <div className="relative cursor-pointer mr-5 group">
                    <img
                      alt="gallery"
                      draggable="false"
                      className="rounded-xl rounded-bl-xl block h-full  w-40 object-cover object-center"
                      src={serviceOffered?.cover_img}
                    />
                    <div className="rounded-xl rounded-bl-xl absolute inset-0  opacity-0 group-hover:opacity-10 transition-opacity duration-300 bg-black"></div>
                  </div>
                  <div>
                    <div>
                      <div className="font-semibold">
                        {serviceOffered?.title}
                      </div>
                      <div className="font-semibold">
                        by {serviceOffered?.merchant_name}
                      </div>
                      <div>{serviceOffered?.subtitle}</div>
                    </div>
                    {Number(serviceOffered.review_count) > 0 && (
                      <div className="flex flex-row font-semibold mt-1 items-center">
                        <div className="w-5 h-5 mr-2">
                          <div className="ribbon w-full h-full bg-no-repeat bg-contain"></div>
                        </div>
                        {serviceOffered.review_count}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap gap-2 justify-between">
                  <div>
                    <p className="text-base font-semibold 2xl:text-xl">
                      Payment Details
                    </p>
                    {serviceOffered.pax && (
                      <p className="self-center mt-1 text-2xs italic">
                        maximum of {serviceOffered.pax} pax
                      </p>
                    )}
                  </div>
                </div>

                {isPricingLoading && (
                  <div className="mt-5">
                    <Loader borderColor="border-primary-500" />
                  </div>
                )}

                {!isPricingLoading && (
                  <div className="border mt-5 rounded-3xl">
                    <div className="text-sm text-gray-500 border-b">
                      <div className="flex flex-wrap justify-between p-3 items-center">
                        <div>Contract Price</div>
                        <div>{servicePricing.service_price} PHP</div>
                      </div>
                    </div>
                    <div className="text-sm text-gray-500 border-b">
                      <div className="flex flex-wrap justify-between p-3">
                        <div>
                          Service Fee
                          {/* <div className="text-2xs">
                            Deductible from the Contract Price
                          </div> */}
                        </div>
                        <div>{servicePricing.service_fee} PHP</div>
                      </div>
                    </div>
                    {servicePricing.total_surcharge !== "0.00" && (
                      <div className="text-sm text-gray-500 border-b">
                        <div className="flex flex-wrap justify-between p-3">
                          <div>Surcharge</div>
                          <div>{servicePricing.total_surcharge} PHP</div>
                        </div>
                      </div>
                    )}
                    {/* <div className="text-sm text-gray-500 border-b">
                      <div className="flex flex-wrap justify-between p-3">
                        <div>Customer Service Fee</div>
                        <div>{servicePricing.service_fee} PHP</div>
                      </div>
                    </div> */}
                    <div className="bg-sunrise-500 rounded-b-3xl text-sm text-white">
                      <div className="flex font-semibold text-white flex-wrap justify-center p-3">
                        <div>{servicePricing.net_amount} PHP</div>
                      </div>
                    </div>
                  </div>
                )}
                {/* <p className="text-xs text-gray-400 mt-4 font-light text-center">
                  This booking form will serve as
                </p>
                <p className="text-xs text-gray-400 font-light text-center">
                  a guide to a seamless negotiation with the merchant.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
