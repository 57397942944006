import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ContentLoader from "react-content-loader";
import { useLocation } from "react-router-dom";
import MetaTags from "../../common/MetaTags";
import { getServices } from "../../../api/client/listing";
import { List } from "./List";
import {
  updateIsSoonLanding,
  updateSearchNameInHome,
  updateServices,
} from "../../../store/slicers/listingSlice";
import { ListingScroll } from "../Listing/List";
import Helmet from "react-helmet";
import ChevronDown from "../../../assets/svg/ChevronDown";
import { HowDoesItWorks } from "../../common/HowDoesItWorksModal";

const Category = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalShow, setModalShow] = useState(false);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const searchData = queryParams.get("search");

  const services = useSelector((state) => state.listing.services);
  // const isSoonLanding = useSelector((state) => state.listing.isSoonLanding);
  const searchNameInHome = useSelector(
    (state) => state.listing.searchNameInHome
  );
  const [isSearch, setSearch] = useState("");
  const listingLoading = useSelector((state) => state.listing.listingLoading);
  const listings = useSelector((state) => state.listing.listings);

  useEffect(() => {
    if (searchData !== null) {
      setSearch(searchData);
      dispatch(updateSearchNameInHome(searchData));
    }
  }, []);

  const fetchServices = async () => {
    try {
      const response = await getServices();
      if (response.error === 0) {
        setIsLoading(false);
        dispatch(updateServices(response.data.filter.services_type));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    if (services.length === 0) {
      setIsLoading(true);
      fetchServices();
    }
  }, [services, dispatch]);

  const loaders = Array.from({ length: 10 }, (_, index) => (
    <ContentLoader
      key={index}
      speed={1}
      width="100%"
      height="15rem"
      viewBox="0 0 195 205"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect rx="20" ry="20" width="100%" height="100%" />
    </ContentLoader>
  ));

  useEffect(() => {
    if (searchNameInHome !== "") {
      setSearch(searchNameInHome);
    } else {
      setSearch("");
    }
  }, [searchNameInHome, dispatch]);

  useEffect(() => {
    if (
      searchNameInHome !== "" &&
      !listingLoading &&
      listings.length === 0 &&
      isSearch !== ""
    ) {
      // dispatch(handleGlobalModal(true, "No listing found"));
      setSearch("");
    }
  }, [listingLoading, listings]);

  return (
    <div className="px-10 lg:px-40 xl:px-56 pb-10 pt-6 align-center items-center">
      <Helmet>
        <MetaTags
          title="Beyond Celebration"
          description="Elevate every experience. Celebrate every moment."
          keywords="genuine event merchants"
        />
      </Helmet>

      <div
        className="w-full relative rounded-2xl h-60 lg:h-96  group"
        style={{ backgroundColor: "#473577CC" }}
      >
        <div className="absolute banner-1  h-full w-full rounded-2xl opacity-20" />
        <div className="absolute gap-y-2 inset-0 flex flex-col items-center text-white justify-center">
          <h2 className="font-pacifico px-2 text-2xl lg:text-2xl text-center  ">
            Beyond Celebrations!
          </h2>
          <p className="text-center text-sm lg:text-base">
            Elevate every experience. Celebrate every moment.
          </p>
          <div
            onClick={() => setModalShow(true)}
            className="w-60 cursor-pointer mt-4 text-xs select-none rounded-full bg-white ring-1 ring-primary-500 flex "
          >
            <div className="items-center py-2  flex-row font-semibold flex w-full">
              <div className="text-primary-400 w-full px-4">
                How does it work?
              </div>
              <div className="rounded-full bg-primary-500 mr-2">
                <ChevronDown />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <h1 className="text-5xl text-center font-semibold text-primary-500 leading-tight">
        Beyond Celebration
      </h1> */}

      <div className="mt-5 sm:mt-10">
        <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-5">
          {!isSearch && isLoading && loaders}
          {!isSearch &&
            !isLoading &&
            services !== null &&
            services.length !== 0 &&
            services.map((data, key) => {
              return (
                <List
                  service_name={data.service_name}
                  key={key}
                  service_type_code={data.service_type_code}
                  service_img={data.service_img}
                  icon_name={data.icon_name}
                  image={data.icon_name}
                />
              );
            })}
        </div>
        {isSearch && <ListingScroll />}
      </div>

      {isModalShow && (
        <HowDoesItWorks handleClose={() => setModalShow(false)} />
      )}
      {/* {isSoonLanding && <SoonMerchant handleClose={() => dispatch(updateIsSoonLanding(false))} />} */}
    </div>
  );
};

export default Category;
